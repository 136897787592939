import service from '@/utils/request';
export var getDealerGroup = function getDealerGroup(params) {
  return service({
    url: "/dealerGroupData/getDealerGroup",
    method: 'get',
    params: params
  });
};
export var getDealerName = function getDealerName(params) {
  return service({
    url: "/dealerGroupData/getDealerName",
    method: 'get',
    params: params
  });
};
export var getDealerCode = function getDealerCode(params) {
  return service({
    url: "/dealerGroupData/getDealerCode",
    method: 'get',
    params: params
  });
};
export var getDealerGroupData = function getDealerGroupData(params) {
  return service({
    url: "/dealerGroupData/getDealerGroupData",
    method: 'get',
    params: params
  });
};

//getDealerGroupByName
export var getDealerGroupByName = function getDealerGroupByName(params) {
  return service({
    url: "/dealerGroupData/getDealerGroupByName",
    method: 'get',
    params: params
  });
};